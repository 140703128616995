
import { defineComponent, reactive, ref, watch} from 'vue'
import httpRequest from '@/service'
import { ArrowDown } from '@element-plus/icons-vue'
import BreadCrumb from '@/components/breadCrumb/index.vue'

export default defineComponent({
  name: 'Home',
  components: { ArrowDown, BreadCrumb },
  setup() {
    interface ITable {
      id?: string
      favor?: boolean
      [key: string]: any
    }
    const tableData = ref<ITable>([])
    const currentPage = ref(1) //当前页数
    const pageSize = ref(10) //页码大小
    const total = ref(0)
    const effTimeId = reactive<ITable>({
      effectId: '',
      timelinessId: ''
    })
    const activeName = ref('1')
    const loading = ref(false)
    const tableLabel = ref<any[]>([])
    const tableUrl = ref('')
    watch(activeName,(val) => {
      switch (val) {
        case '1':
          tableUrl.value = 'browsingHistoryTable'
          tableLabel.value = [
            { prop:'index', label:'序号',width:70 },
            { prop:'lawName', label:'标题',align:'left' },
            { prop:'publishOrgName', label:'发布机关',align:'left' },
            { prop:'publishDate', label:'发布日期',width:120 },
            { prop:'implDate', label:'实施日期',width:120  },
            { prop:'effectLevel', label:'效力级别',width:120  },
            { prop:'timeliness', label:'时效性',width:120  },
            { prop:'historyTime', label:'浏览时间',width:120  },
          ]
          break
        case '2':
          tableUrl.value = 'browsingHistoryTable2'
          tableLabel.value = [
            { prop:'index', label:'序号',width:70 },
            { prop:'caseName', label:'案号',align:'left' },
            { prop:'caseType', label:'案件类型',width:120 },
            { prop:'category', label:'行政管理类别',width:120 },
            { prop:'reconsiderMatter', label:'复议事项',width:120 },
            { prop:'closeType', label:'结案类型',width:120 },
            { prop:'historyTime', label:'浏览时间',width:120 },
          ]
          break
        case '3':
          tableUrl.value = 'browsingHistoryTable3'
          tableLabel.value =  [
            { prop:'index', label:'序号',width:70 },
            { prop:'cpwsAlAjzh', label:'案号',align:'left' },
            { prop:'cpwsAlZsDate', label:'裁判日期',width:120 },
            { prop:'cpwsAlCaseSortName', label:'案件/罪名',width:120 },
            { prop:'cpwsAlFyjbName', label:'法院级别',width:120 },
            { prop:'cpwsAlWslxName', label:'文书类型',width:120 },
            { prop:'cpwsAlSlfyName', label:'审理法院',width:120 },
            { prop:'cpwsAlSlcxName', label:'审理程序',width:120},
            { prop:'historyTime', label:'浏览时间',width:120 },
          ]
          break
        case '4':
          tableUrl.value = 'browsingHistoryTable4'
          tableLabel.value = [
            { prop:'index', label:'序号',width:70 },
            { prop:'caseNo', label:'案号',align:'left' },
            { prop:'caseTypeName', label:'案件类型',width:120 },
            { prop:'court', label:'审判法院',width:120 },
            { prop:'cause', label:'案由',width:240 },
            { prop:'refereeDate', label:'发布时间',width:120 },
            { prop:'historyTime', label:'浏览时间',width:120 },
          ]
          break
      }
      getTableData()
    },{immediate:true})
    
    const levelTypes = ref<ITable>([])
    const timeTypes = ref<ITable>([]) //时效性数据
    
    // 表格数据
    function getTableData (){
      loading.value = true
      httpRequest
        .post({
          url: tableUrl.value,
          data: {
            page: currentPage.value,
            limit: pageSize.value,
            // effectId: effTimeId.effectId,
            // timelinessId: effTimeId.timelinessId
          }
        })
        .then((res: any) => {
          tableData.value = res.data.list
          total.value = res.data.total
          loading.value = false
        })
        .catch((err) => {
          console.log(err)
          loading.value = false
        })
    }
    // 效力级别
    const getLevelData = () => {
      httpRequest
        .post({ url: 'effectLevelList' })
        .then((res: any) => {
          levelTypes.value = res.data
          let value = { id: '', name: '全部' }
          levelTypes.value.unshift(value)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 时效性
    const getTimeData = () => {
      httpRequest
        .post({ url: 'timelinessList' })
        .then((res: any) => {
          timeTypes.value = res.data
          let value = { id: '', name: '全部' }
          timeTypes.value.unshift(value)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 当前页数
    const handleCurrentChange = (val: number) => {
      currentPage.value = val
      getTableData()
    }
    // 改变页码大小
    const handleSizeChange = (val: number) => {
      pageSize.value = val
      getTableData()
    }
    // 下拉菜单-效力级别
    const effectCommand = (command: any) => {
      effTimeId.effectId = command
      currentPage.value = 1
      getTableData()
    }
    // 下拉菜单-时效性
    const timeCommand = (command: any) => {
      effTimeId.timelinessId = command
      currentPage.value = 1
      getTableData()
    }
    getLevelData()
    getTimeData()
    return {
      total,
      tableData,
      tableLabel,
      levelTypes,
      timeTypes,
      currentPage,
      effTimeId,
      pageSize,
      handleCurrentChange,
      effectCommand,
      timeCommand,
      handleSizeChange,
      activeName,
      loading
    }
  }
})
